import React from "react";
import Facebook from "../../assets/Facebook.svg";
import Instagram from "../../assets/Insta.svg";
import Google from "../../assets/google.svg";

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="left-section">
        <p>Let’s get social</p>

        <div className="icon-section">
          <a href="https://www.facebook.com/vipparkingcontrol" target="_blank">
            <img src={Facebook} />
          </a>
          <a
            href="https://www.instagram.com/vipparkingcontrol/"
            target="_blank"
          >
            <img src={Instagram} />
          </a>
          <a href="https://vipparkingcontrol.com/" target="_blank">
            <img src={Google} />{" "}
          </a>
        </div>
      </div>
      <div className="link-section">
        <p>Operated by:</p>
        <a target="_blank" href="https://www.hholding-lb.com">
          Hholding
        </a>
      </div>
    </div>
  );
};

export default Footer;
