import React from "react";
import Logo from "../assets/LogoJpeg.jpeg";
const WelcomePage = () => {
  return (
    <div className="welcome-container">
      <h1>Thank You</h1>
      <h4>
        Thank you for being our valued customer. We are happy to serve you,{" "}
        <br /> and hope we met your expectations
      </h4>
      <div className="img-container">
        <img src={Logo} />
      </div>
    </div>
  );
};

export default WelcomePage;
