import WelcomePage from "./pages/WelcomePage";
import RequestPage from "./pages/RequestPage";
import "./styles/styled.scss";
import { useParams } from "react-router-dom";

function App() {
  const { siteId, id, code } = useParams();

  return (
    <>
      {siteId && id && code ? (
        <div className="App">
          <RequestPage siteId={siteId} TransactionId={id} code={code} />
        </div>
      ) : (
        <WelcomePage />
      )}
    </>
  );
}

export default App;
