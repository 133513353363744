import { useWebClient } from "../pages/provider";

function viewDate(date) {
  if (date) {
    var d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    let hour = d.getHours();
    let min = d.getMinutes();
    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;
    if (hour < 10) hour = "0" + hour;
    if (min < 10) min = "0" + min;
    var utc =
      year +
      "-" +
      month +
      "-" +
      day +
      "T" +
      hour +
      ":" +
      min +
      ":" +
      "00.000" +
      "Z";
    var local = new Date(utc).toLocaleString("en-AU");
    console.log(local, "local");
    // var viewDate = new Date(local);
    // var day1 = viewDate.getDate();
    // var month1 = viewDate.getMonth() + 1;
    // var year1 = viewDate.getFullYear();
    // var hours1 = viewDate.getHours();
    // var min1 = viewDate.getMinutes();

    // return `${day1}-${month1}-${year1}  ${hours1}:${min1}`;
    return local;
  }
}

export { viewDate };
