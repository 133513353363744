import { useCallback } from "react";
import { apiUrl as baseUrl } from "../config";

export const useRequest = () => {
  // var data;
  return useCallback((...params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await request(...params);

        if (res.status === 401) {
          window.location.replace("/");
          reject({ status: res.status, errors: "Unauthorised Access" });
        } else if (res.status === 403) {
          window.location.replace("/no-access");
          reject({ status: res.status, errors: "Access Denied" });
        } else if (res.status === 404) {
          window.location.replace("/");
          reject({ status: res.status, errors: "NOT FOUND" });
        } else if (!res.ok) {
          const eData = await res.json();
          reject({ status: res.status, errors: eData });
        }
        const resData = await (res.status !== 204 ? res.json() : {});
        resolve(resData);
      } catch (error) {
        reject(error);
      }
    });
  }, []);
};

export default function request(
  url,
  data = null,
  options = {},
  withAuth = false,
  headers = {}
) {
  const defaultOptions = {
    method: data ? "POST" : "GET",
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    ...options,
  };
  try {
    if (data)
      if (defaultOptions.headers["Content-Type"] === "application/json")
        defaultOptions.body = JSON.stringify(data);
      else defaultOptions.body = data;
    if (withAuth)
      defaultOptions.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem("tokens"))?.accessToken
      }`;
    return fetch(`${baseUrl}/${url}`, defaultOptions);
  } catch (error) {
    throw new Error(error);
  }
}
