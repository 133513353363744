import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import WelcomePage from "./pages/WelcomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Providers from "./services/Providers";
import RequestPage from "./pages/RequestPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Providers>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/:siteId/:id/:code" element={<RequestPage />} />
          <Route path="/" element={<WelcomePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Providers>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
