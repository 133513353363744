import React from "react";
const Header = ({ logo, companyTitle, siteTitle }) => {
  return (
    <div className="header-section">
      <div className="logo-container">
        {logo ? <img src={logo} alt="logo" /> : ""}
      </div>
      <div className="text-wrapper">
        <h1>{companyTitle}</h1>
        <h1>{siteTitle}</h1>
      </div>
    </div>
  );
};

export default Header;
